/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .App {
    flex-direction: row;
    justify-content: space-between;
  }
}

body, #root {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

#root {
  background-image: url('./assets/bg_svg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Add an overlay to improve text readability */
#root::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity as needed */
  z-index: -1;
}


.glassmorphic-bg {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  backdrop-filter: blur(10px);           /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px);   /* For Safari compatibility */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border for depth */
  border-radius: 20px;                   /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  max-width: 350px;                      /* Set a max-width for the glassmorphic effect */
  width: fit-content;                    /* Make it responsive to content */
  text-align: center;                   /* Center text */
}

.glowing-text {
  text-shadow: 0 0 5px rgba(200, 200, 255, 0.6), 
               0 0 9px rgba(200, 200, 255, 0.4), 
               0 0 9px rgba(200, 200, 255, 0.2);
  color: white; /* Ensure text color is set to white or another visible color */
}

.contact-form {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 20px;
}

input, textarea {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
}

input:focus, textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
}